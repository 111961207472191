<template>
  <div class="btn-group" :class="{'opacity-button': !isMe}">
    <button class="btn btnoutline dropdown-toggle" type="button" id="dropdownMenuClickableInside"
      data-bs-toggle="dropdown" data-bs-auto-close="outside" ref="passengerDropdown">
      <strong>{{$t("sabre.search-panel.passenger-selection")}} </strong>
      <span><i class="fa-solid fa-user"></i></span>
      <small style="min-width:auto;">{{compositionString.length > 15 ? `${compositionString.slice(0,15)} ...` : compositionString}}</small>
    </button>

    <select-passenger propsClassName="dropdown-menu fromwheredropdown compositiondropdown dropdown-menu-end"
    type="dropdown" aria-labelledby="dropdownMenuClickableInside" @close="closeDropdown"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dropdownOpenState from '@/sabre/common/mixins/dropdownOpenState';

export default {
  name: 'search-mobile-down-pane',
  mixins: [dropdownOpenState],
  components: {
    SelectPassenger: () => import('./selectPassenger'),
  },
  computed: {
    ...mapGetters({
      adults: 'GET_SABRE_ADULTS_FOR_SEARCH',
      children: 'GET_SABRE_CHILDREN_FOR_SEARCH',
      babies: 'GET_SABRE_BABIES_FOR_SEARCH',
      seniors: 'GET_SABRE_SENIORS_FOR_SEARCH',
    }),
    compositionString() {
      const { adults, children, babies, seniors } = this;
      let str = '';
      if (adults) str += `${adults} ${this.$t('sabre.composition.adults')}`;
      if (children) str += ` ${children} ${this.$t('sabre.composition.children')}`;
      if (babies) str += ` ${babies} ${this.$t('sabre.composition.infants')}`;
      if (seniors) str += ` ${seniors} ${this.$t('sabre.composition.seniors')}`;
      return str;
    },
  },
  methods: {
    closeDropdown() {
      window.bootstrap.Dropdown.getOrCreateInstance(this.$refs.passengerDropdown).hide();
    },
  },
};
</script>
